import React from "react"
import './App.css';

import Collection from "./collection"

function App() {
  return (
        <html lang="en">
            <Collection />
        </html>
  );
}

export default App;
